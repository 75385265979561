import { Fragment, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useAuth } from "../../lib/use-auth";
import SignIn from "./SignIn";
import { Modal, Section, Box } from "react-bulma-components";
import PageLayout from "./Layout";
import { UNDEFINED } from "../../constants";
import noop from "lodash/noop";

const FullScreenAuth = props => {
  return (
    <PageLayout>
      {typeof window !== UNDEFINED && <SignIn {...props} />}
    </PageLayout>
  );
};

const AuthModal = ({
  show,
  onClose,
  showClose = true,
  closeOnEsc = true,
  success,
  header
}) => {
  return (
    <Modal
      show={show}
      showClose={showClose}
      closeOnEsc={closeOnEsc}
      onClose={onClose}
      closeOnBlur={closeOnEsc}
    >
      <Modal.Content>
        <Section>
          <Box>
            {typeof window !== UNDEFINED && (
              <SignIn success={success} header={header} />
            )}
          </Box>
        </Section>
      </Modal.Content>
    </Modal>
  );
};

export const withAuthModal = (Component, noopClose = false) => props => {
  const { user } = useAuth();
  const router = useRouter();
  const [show, setShow] = useState(false);
  const [requestedModal, setRequestedModal] = useState(false);
  // const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (requestedModal && !user) {
      const timer = setTimeout(() => {
        if (!user) {
          setShow(true);
        }
      }, 2000);
      return () => clearTimeout(timer);
    }
    if (user) {
      setRequestedModal(false);
      setShow(false);
    }
  }, [user, requestedModal]);

  const onClose = () => {
    if (noopClose) {
      setShow(false);
    } else {
      if (!user) {
        router.push("/login");
      } else {
        setShow(false);
      }
    }
  };

  return (
    <Fragment>
      <AuthModal
        success={noop}
        show={show}
        closeOnEsc={true}
        onClose={onClose}
        header="Sign In"
      />
      <Component
        openAuthModal={(immediate = false) => {
          if (immediate) {
            setShow(true);
          } else {
            setRequestedModal(true);
          }
          // scrollTo(0, 0);
        }}
        {...props}
      />
    </Fragment>
  );
};

export default FullScreenAuth;
