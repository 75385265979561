import {
  Section,
  Box,
  Columns
} from "react-bulma-components";

const AuthPageLayout = ({ children }) => (
  <Section>
    <Columns>
      <Columns.Column size="one-third" offset="one-third">
        <Box>{children}</Box>
      </Columns.Column>
    </Columns>
  </Section>
);

export default AuthPageLayout;
