import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import SignIn from "../components/auth/SignIn";
import FullScreenAuth from "../components/auth";
import { useRouter } from "next/router";
import { DEFAULT_SIGNIN_REDIRECT } from "../constants";
import { useAuth } from "../lib/use-auth";

const SignInView = () => {
  const { user } = useAuth();
  const router = useRouter();
  let next = router.query.next || DEFAULT_SIGNIN_REDIRECT;

  useEffect(() => {
    if (typeof window !== "undefined" && user) {
      router.push(next);
    }
  }, [user]);

  if (user) {
    return null;
  }
  // {/* {verifying ? <div>Loading...</div> : <SignIn next={pageQuery.next} />} */}
  return <FullScreenAuth next={next} />;
};

export default SignInView;
